<template>
  <q-layout view="lHh Lpr lFf">
    <q-header class="bg-dark">
      <q-toolbar>
        <nuxt-link
          to="/"
          class="flex q-ml-sm"
        >
          <img
            src="/logos/kygunco.svg"
            width="142"
            height="50"
          >
        </nuxt-link>

        <q-space />

        <q-btn
          to="/"
          class="text-weight-bold"
          label="Back"
          stretch
          flat
        />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <slot />
    </q-page-container>

    <q-footer>
      <div class="flex flex-center">
        <q-btn
          label="Need Help?"
          href="/contact"
          stretch
          flat
        />
        <q-separator
          vertical
          inset
        />
        <q-btn
          stretch
          flat
          class="text-weight-bold"
          label="(502) 348-3594"
          href="tel:5023483594"
        />
      </div>
    </q-footer>
  </q-layout>
</template>

<script lang="ts" setup>
const quoteStore = useQuoteStore();
const checkoutStore = useCheckoutStore();

onUnmounted(() => {
  quoteStore.reset();
  checkoutStore.reset();
});
</script>
